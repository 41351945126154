import request from "@/utils/request"




export function inTopic (params) {
	return request({
		url: "/api/topic/index",
		method: "get",
		params,
	})
}

export function inDustry (params) {
	return request({
		url: "api/news/index",
		method: "get",
		params,
	})
}
export function infind (params) {
	return request({
		url: "api/news/find",
		method: "get",
		params,
	})
}

//课程列表
export function getCourseList (params) {
	return request({
		url: "api/course/index",
		method: "get",
		params,
	})
}

//政策解读列表
export function getExplainList (params) {
	return request({
		url: "api/policy.explain/index",
		method: "get",
		params,
	})
}

//政策解读分类列表
export function getPolicList (params) {
	return request({
		url: "api/policy.category/index",
		method: "get",
		params,
	})
}

///政策解读详情
export function getPolicFind (params) {
	return request({
		url: "api/policy.explain/find",
		method: "get",
		params,
	})
}

//政策申报列表
export function getPolicyList (params) {
	return request({
		url: "/api/policy.report/index",
		method: "get",
		params,
	})
}

//政策申报详情
export function getReportList (params) {
	return request({
		url: "/api/policy.report/find",
		method: "get",
		params,
	})
}

//在线申报
export function schemeFrom (data) {
	return request({
		url: `/api/msg/add`,
		method: "post",
		data,
	})
}

//留言
export function schemeRemark (data) {
	return request({
		url: `/api/submit.info/add`,
		method: "post",
		data,
	})
}
// 上传
export function upload (data) {
	return request({
		url: `/api/ajax/upload`,
		method: "post",
		data,
		headers: {
			// 'Access-Control-Allow-Credentials': true,
			"content-type": "application/x-www-form-urlencoded; charset=UTF-8", // 设置完以后 传入的params对象就会时候用formdata传参的方式
		},
	})
}

//政策列表
export function getPolList (params) {
	return request({
		url: "/api/policy/index",
		method: "get",
		params,
	})
}

//政策详情
export function getPiuyList (params) {
	return request({
		url: "/api/policy/find",
		method: "get",
		params,
	})
}

//省市区
export function getAreaList (params) {
	return request({
		url: "api/system.city/index",
		method: "get",
		params,
	})
}

export function getCos () {
	return request({
		url: "/api/system.config/getStsConfig",
		method: "get",
	})
}
export function infoNews (params) {
	return request({
		url: `/api/news/index`,
		method: "get",
		params,
	})
}

export function getDetail (params) {
	return request({
		url: 'api/news/find',
		method: 'get',
		params
	})
};



export function identity (params) {
	return request({
		url: 'api/msg.category/index',
		method: 'get',
		params
	})
};

export function yanZheng (params) {
	return request({
		url: `/api/login/send`,
		method: "get",
		params,
	})
}
