export function ScrollObserver (config) {
    // 存储配置信息
    let info = config.info || {}
    const original = { ...config.headerList } || {
        color: "black",
        background: true
    }
    let headerList = config.headerList || {
        color: "black",
        background: true
    }
    this.isAttached = false

    // 固定的触发距离
    const triggerDistance = 700

    // 更新可见性的方法
    this.updateVisibility = function () {
        const scrollPosition = window.scrollY
        const isVisible = scrollPosition >= triggerDistance

        if (isVisible) {
            headerList.color = headerList.color === "white" ? 'black' : 'black'
            // headerList.background = true
        } else {
            headerList.color = original.color
            headerList.background = original.background
        }
    }

    // 绑定滚动事件
    this.attach = function () {
        if (!this.isAttached) {
            window.addEventListener("scroll", this.updateVisibility)
            this.isAttached = true
        }
    }

    // 移除滚动事件
    this.detach = function () {
        if (this.isAttached) {
            window.removeEventListener("scroll", this.updateVisibility)
            this.isAttached = false
        }
    }

    // 公共方法来启动和停止观察
    this.start = function () {
        this.attach()
    }

    this.stop = function () {
        this.detach()
    }
}