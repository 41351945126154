import Vue from "vue"
import VueRouter from "vue-router"
import Layout from "@/layout"
Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		component: Layout,
		redirect: "/home",
		children: [
			{
				path: "home",
				name: "Home",
				component: () => import("@/views/Home/home"),
				meta: { title: "Home" },
			},
			{
				path: "news",
				name: "news",
				component: () => import("@/views/news/index"),
				meta: { title: "news" },
			},
			{
				path: "detail",
				name: "/detail",
				component: () => import("@/views/news/detail"),
				meta: { title: "detail" },
			},
			{
				path: "our",
				name: "our",
				component: () => import("@/views/Our/index"),
				meta: { title: "Our" },
			},
			{
				path: "ai",
				name: "ai",
				component: () => import("@/views/IntelligentAI/ai"),
				meta: { title: "Our" },
			},
			{
				path: "consumer",
				name: "consumer",
				component: () => import("@/views/Solution/consumer"),
				meta: { title: "Solution" },
			},
			{
				path: "vendor",
				name: "vendor",
				component: () => import("@/views/Solution/vendor"),
				meta: { title: "Solution" },
			},
			{
				path: "produce",
				name: "busproduceiness",
				component: () => import("@/views/Solution/produce"),
				meta: { title: "Solution" },
			},
			{
				path: "business",
				name: "business",
				component: () => import("@/views/Solution/business"),
				meta: { title: "Solution" },
			},
			{
				path: "category",
				name: "category",
				component: () => import("@/views/Sentiment/category"),
				meta: { title: "Sentiment" },
			},
			{
				path: "cooperation",
				name: "cooperation",
				component: () => import("@/views/Sentiment/cooperation"),
				meta: { title: "Sentiment" },
			},
			{
				path: "onlineStore",
				name: "onlineStore",
				component: () => import("@/views/Sentiment/onlineStore"),
				meta: { title: "Sentiment" },
			},
			{
				path: "storesNationwide",
				name: "storesNationwide",
				component: () => import("@/views/Sentiment/storesNationwide"),
				meta: { title: "Sentiment" },
			},
			{
				path: "joinUs",
				name: "joinUs",
				component: () => import("@/views/Sentiment/joinUs"),
				meta: { title: "Sentiment" },
			},
			{
				path: "factory",
				name: "factory",
				component: () => import("@/views/Sentiment/factory"),
				meta: { title: "Sentiment" },
			},
		],
	},


]

const router = new VueRouter({
	routes,
})

export default router
